.modalContainer {
    position: fixed;
    width: 100vw; 
    height: 100%;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .modal{
    width: 90%;
    max-width: 500px;
    border: 5px;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    box-sizing: border-box;
  }
  
  .modal__head{
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  
  .modal__close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .modal__close:hover{
    color: hsl(0, 70%, 70%);
  }