.header .container {
    justify-content: center;
  }
  .input-group-text {
    background-color: #212529;
    color: #fff;
    font-weight: bold;
  }
  .edit,
  .delete {
    padding: 0px 6px;
    border-radius: 2px;
  }
  
  .edit {
    margin-right: 10px;
  }
  table {
    text-align: center;
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .employeeProfileHeader {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    margin-left: 13px;
    padding-left: 35px;
    align-items: self-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    font-family: 'Inter', sans-serif;
  }
  .employeeProfileHeaderName {
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
  }
  .employeeProfileHeaderJobTitle {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    color: #8B8B8B;
  }
  .employeeProfileHeaderDepartment {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    color: #8B8B8B;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .addModulAssignment{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    z-index: 100;
  }
  
  .addModulAssignment input{
    height: 40px;
    border: unset;
    font-size: 18px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border-bottom: 1.5px solid hsl(210, 10%, 75%);
    padding-left: 5px;
  }
  
  .addModulAssignment input:focus{
    outline: none;
  }
  
  .addModulAssignment button {
    height: 40px;
    border: none;
    color: white;
    background-color: hsl(52, 80%, 25%);
  }
  
  .addModulAssignment textarea {
    height: 150px;
    border-color: hsl(210, 10%, 75%);
    padding: 5px;
    font-size: 18px;
  }
  
  .addModulAssignment textarea:focus { 
    outline: none;
  }
  
  .addModulAssignment button:hover {
    background-color: hsl(210, 12%, 25%);
  }
  
  .addModulAssignment button:active {
    background-color: hsl(210, 12%, 15%);
  }