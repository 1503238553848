.sidebar {
  flex: 0;
  background-color: #F2F2F2;
  position: relative;
  top: 0px;
  height: 1024px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.logo{
  cursor: pointer;
  padding-top: 5%;
}

.sidebarMenu {
  margin-bottom: 450px;
}

.sidebarMenuBottom {
  margin-bottom: 5px;
  position: bottom;

}

.sidebarTitle {
  font-size: 13px;
  color: #FFFFFF;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #258ee4;
  color: antiquewhite;
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
    color: rgb(255, 0, 174);
}

.active{
  stroke: rgb(95, 93, 94);
  color: antiquewhite;
  filter: invert(37%) sepia(95%) saturate(691%) hue-rotate(166deg) brightness(96%) contrast(103%);
}

.inactive{
  stroke: #7E7E7E;
  color: rgb(0, 0, 0);
  filter: invert(43%) sepia(98%) saturate(0%) hue-rotate(186deg) brightness(94%) contrast(93%);
}

