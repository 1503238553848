.MuiCard-root {
    overflow: hidden;
    padding: 5px;
    border-left: green solid;
    font-size: 14px;
    background: rgba(249,249,251,1);
    opacity: 1;
    font-family: Inter;
  }
.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 20px;
    font-family: Inter;
  }
.card-header-title {
    font-size: 20px;
    font-family: Inter;
  }
.btn-small {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;
    font-family: Inter;
    border: 0px;
    background-color: white;
  }
  .addmodbtn {
    position: relative;
    margin-top: 0%;
    left:90%;
    border: 0px;
    background-color: white;
  }
