.auth{ 
    width: 75%;
    box-sizing: inherit;
    align-items: normal;
    text-align: center;
    justify-content: center;
  }
  
  .auth form{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto 10px auto;
  }
  
  .auth__success{
    padding: 10px 0;
    background-color: hsl(147, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth__error{
    padding: 10px 0;
    background-color: hsl(0, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth form > input, button{
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
  }

  .login_logo{
    cursor: pointer;
    padding-top: 5%;
    max-height: 50%;
    padding-bottom: 15px;
  }